<template>
  <div class="profile-table-header df jc-sb ai-c">
    <div class="profile-table-header__search-block">
      <div class="profile-table-header__inputs">
        <el-input
          v-model="search"
          placeholder="поиск"
          prefix-icon="el-icon-search"
          class="table-input"
          clearable
          @keydown.native.enter="handleEmitFilterParams"
          @input="debounceEmitFilterParams"
        />
      </div>
    </div>

    <div class="profile-table-header__btn-wrap">
      <i
        class="el-icon-circle-plus profile-table-header__btn"
        @click="handleClickIcon"
      />
    </div>
  </div>
</template>

<script>
import { PROFILE_NEW_USER } from '@/constants/dialogs'
import { debounce } from '@/core'

export default {
  name: 'ProfileTableHeader',
  data() {
    return {
      search: '',
    }
  },
  methods: {
    debounceEmitFilterParams() {
      debounce(this.handleEmitFilterParams, 1000)
    },
    handleEmitFilterParams() {
      this.$emit('emitFilterParams', {
        search: this.search,
      })
    },
    handleClickIcon() {
      this.setDialog({
        name: PROFILE_NEW_USER,
        visible: true,
      })
    },
  },
}
</script>

<style lang="sass">
.profile-table-header
  padding: $space-20
  background: #F4F7FC
  border-radius: 6px 6px 0 0
  +to(1024px)
    flex-wrap: wrap

  &__search
    flex: 0 0 20%
    width: 20%
    max-width: 195px
    +to(1024px)
      flex: 0 0 40%
      width: 40%
      max-width: 100%
    +to(700px)
      flex: 0 0 100%
      width: 100%

  &__inputs
    flex: 0 0 100%
    display: flex
    justify-content: flex-start
    align-items: center
    margin-bottom: 16px
    & > *
      flex: 0 0 calc((100% - 44px) / 3)

  &__search-block
    display: flex
    align-items: center
    justify-content: center
    flex: 0 1 100%
    flex-wrap: wrap

  &__btn
    font-size: 26px
    color: #C4C4C4
    cursor: pointer
    transition: all 0.3s ease
    &:hover
      transform: scale(1.2)
      color: #868585
</style>
